.form-label {
    @apply font-medium text-blue text-xl mt-2 lg:mt-8 lg:mb-2
}

.form-sublabel {
    @apply text-blue text-lg font-light
}

.form-input-container { 
    @apply mb-4
}

.form-radio-text {
    @apply text-base text-slate ml-1 mr-4
}

.form-error {
    @apply text-red-500 lg:text-sm text-lg 
}

.form-input {
    @apply h-8 rounded border border-solid border-gray-300 shadow bg-white pl-2
}

.placeholder-blue {
    ::placeholder {
        color: #175e88;
    }
}