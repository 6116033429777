.title {
    @apply text-blue text-3xl font-medium lg:mb-2;
}

.header-link {
    @apply text-blue text-xl mx-4 my-1 lg:my-0 font-medium;
}

.header-link-active {
    @apply text-slate cursor-default;
}

.header-text {
    @apply font-bold text-xl text-slate;
}
