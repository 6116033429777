@tailwind base;
@tailwind components;
@tailwind utilities;

.bottom-shadow {
  box-shadow: 0 8px 5px -5px #00000026;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, #root {
  height: 100%
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a { 
  text-decoration: none;
  color: inherit;
  text-decoration: inherit;
}

svg {
  display: inline;
}

/* Overwrite leaflet styles to display popups/tooltips how we want */
.leaflet-tooltip{
  background:none;
  border:none;
  box-shadow:none;
  font-weight:bold;
  font-size:2em;
  color: black;
  padding: none;
}

.leaflet-popup-content{
  width: 500px !important;
}