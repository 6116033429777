.fa-spin {
    animation: fa-spin 2s infinite linear;
}
@keyframes fa-spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
    }
}

.fadeOut {
    animation: hideAnimation 2s ease-in 10s;
    animation-fill-mode: forwards;
}

@keyframes hideAnimation {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 0;
        height: 0;
        visibility: hidden;
    }
}
.fadeIn {
    animation: fadeIn 3s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% : {
        opacity: 1;
    }
}

.shake {
    animation: shake 2s;
    animation-iteration-count: 5;
}

@keyframes shake {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }
    3% {
        transform: translate(-1px, -2px) rotate(-1deg);
    }
    6% {
        transform: translate(-2px, 0px) rotate(1deg);
    }
    9% {
        transform: translate(2px, 2px) rotate(0deg);
    }
    12% {
        transform: translate(1px, -1px) rotate(1deg);
    }
    15% {
        transform: translate(-1px, 2px) rotate(-1deg);
    }
    18% {
        transform: translate(-2px, 1px) rotate(0deg);
    }
    21% {
        transform: translate(2px, 1px) rotate(-1deg);
    }
    24% {
        transform: translate(-1px, -1px) rotate(1deg);
    }
    27% {
        transform: translate(1px, 2px) rotate(0deg);
    }
    30% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
    33% {
        transform: none;
    }
}
